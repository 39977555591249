/* ---- */

.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
  border-radius: 8px;
}
.slick-loading .slick-slide,
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  border-radius: 8px;
}
.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: 0;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  top: 0;
  left: 0;
}
.slick-track:after,
.slick-track:before {
  display: table;
  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  border-radius: 8px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
  border-radius: 8px;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
} /*# sourceMappingURL=slick.min.css.map */

/* ikinji arrow dots */

.slick-dots,
.slick-next,
.slick-prev {
  position: absolute;
  display: block;
  padding: 0;
}
.slick-dots li button:before,
.slick-next:before,
.slick-prev:before {
  font-family: slick;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* .slick-loading .slick-list {
  background: url(ajax-loader.gif) center center no-repeat #fff;
} */

.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
}
.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  color: transparent;
  outline: 0;
  background: 0 0;
}
.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
  opacity: 1;
}
.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: 0.25;
}
.slick-next:before,
.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #fff;
}
.slick-prev {
  left: 10px;
  z-index: 1000;
}
[dir="rtl"] .slick-prev {
  right: 10px;
  left: auto;
}
.slick-prev:before {
  /* content: "<"; */
  background-image: url("../../img/icons/prev.svg");
  background-size: 16px 16px;
  display: inline-block;
  width: 20px;
  height: 20px;
  content: "";
  fill: #010101;
  background-color: #f0f0f0;
  padding: 16px 16px 16px 16px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
}
.slick-next:before {
  background-image: url("../../img/icons/next.svg");
  background-size: 16px 16px;
  display: inline-block;
  width: 20px;
  fill: #010101;
  height: 20px;
  content: "";
  background-color: #f0f0f0;
  padding: 16px 16px 16px 16px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
}
.slick-next {
  right: 22px;
}
[dir="rtl"] .slick-next {
  right: auto;
  left: 25px;
}
[dir="rtl"] .slick-next:before {
  content: "<";
}
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  /* bottom: -20px; */
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 1px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 12px;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}
.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0;
}

.slick-dots li button:hover {
  outline: 0;
  opacity: 0.8;
}
.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  /* top: -30px; */
  left: 0;
  width: 20px;
  height: 20px;
  content: "";
  text-align: center;
  background: url("../../img//icons/dot.svg");
  color: #000;
  background-repeat: no-repeat;
  opacity: 0.25;
}
.slick-dots li.slick-active button:before {
  opacity: 0.85;
  color: #000;
} /*# sourceMappingURL=slick-theme.min.css.map */

@media only screen and (min-width: 0px) and (max-width: 991px) {
  .slick-dots li button:before {
    position: absolute;
    /* top: -30px; */
    left: 0;
    background-image: none;
    width: 10px;
    height: 10px;
    content: "⚫";
    text-align: center;
    color: #000;
    opacity: 0.25;
  }

  .slick-prev:before {
    /* content: "<"; */
    background-image: url("../../img/icons/prev.svg");
    background-size: 16px 16px;
    display: inline-block;
    width: 14px;
    height: 14px;
    content: "";
    background-color: #f0f0f0;
    padding: 12px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
    fill: #010101;
  }
  .slick-next:before {
    background-image: url("../../img/icons/next.svg");
    background-size: 16px 16px;
    display: inline-block;
    width: 14px;
    fill: #010101;
    height: 14px;
    content: "";
    background-color: #f0f0f0;
    padding: 12px 12px 12px 12px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
  }
  .slick-next {
    right: 16px;
  }
}
