@import "~antd/dist/antd.css";

body {
  font-family: "Nunito";
  background-color: #f2f5f7;
}
html {
  height: 100%;
  --antd-wave-shadow-color: #ffffff;
}

.ant-layout {
  background-color: #f2f5f7;
}
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar:horizontal {
  height: 6px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-left: 1px solid #aaaaaa;
}

/* 





*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #888;
}
::-webkit-scrollbar-thumb:hover {
  /* background: rgb(255, 46, 46); */
  background-color: rgb(107, 106, 106);
  cursor: pointer;
}

.sider {
  background-color: #f2f5f7;
}
