/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: red;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px red, 0 0 5px red;
  opacity: 1;
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: red;
  border-left-color: red;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.main-loading {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* height: 150px;
  width: 150px; */
}

.menu {
  height: 80px;
}
.h-container {
  margin: 0 auto;
  width: 94%;
  display: flex;
  max-width: 100em;
  justify-content: space-between;
  margin-bottom: 6px;
}

.h-container-smart {
  width: 75%;
  display: flex;
  max-width: 100em;

  margin-bottom: 6px;
  margin-left: 15%;
}

.smart-categories {
  text-align: center;
  margin: 0px auto;
  margin-left: 15%;
  margin-top: -30px;
  height: 20px;
}

.header-manat {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  position: relative;
  line-height: 16px;
}

.smart-categories-item {
  margin-left: 20px;
}

.r-sebet {
  font-size: 26px;
  position: relative;
  color: #fd0018;
  top: 4px;
}
.menu-items-time,
.menu-items-lang,
.menu-items-contact,
.menu-items-saved,
.menu-items-profile {
  margin-top: 10px;
  margin-right: 0px;
  text-align: center;
  font-size: 14px;
}

.cart-width {
  width: 150px;
}

.menu-items-sebet {
  margin-top: 12px;
  text-align: left;
  font-size: 14px;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 20px;
  height: 36px;
  padding-left: 10px;
  padding-right: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  float: right;
  line-height: 36px;
}

.h-container .menu-items {
  margin-top: 10px;
  margin-right: 4px;
  text-align: center;
  font-size: 14px;
}

.r-show {
  display: none;
}

.menu-icons {
  font-size: 24px;
  position: relative;
  color: #FF7700;
  top: 8px;
}

.menu-text {
  color: #000;
  position: relative;
  left: 5px;
  top: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 12px;
}

.top-header {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}

.h-line {
  border-left: 1px solid rgba(212, 199, 199, 0.747);
  height: 32px;
  margin-top: 15px;
  position: relative;
  margin-left: 2px;
}
.btn-order {
  background-color: #000;
  color: #fff;
  border-radius: 4px;
  width: 100% !important;
  cursor: pointer;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
}

.tags {
  background-color: #fff;
  text-align: center;
}

.h-sidebar {
  margin-top: 100px;
  width: 220px;
  background-color: #f2f5f7;

  margin-right: 20px;
}

.category-list {
  background-color: #fff;
  border-radius: 8px;
  margin-right: 8px;
  width: 100%;
}

.h-cat {
  font-size: 18px;
  line-height: 12px;
  padding-left: 8px;
  border-bottom: 1px solid #e4e5e9;
  color: #747474;
  font-style: normal;
  font-weight: normal;
}

.h-catlist {
  border: 0;
  font-size: 16px;
  font-weight: bold;
  padding-left: 2px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
  padding-top: 8px;
  height: 40px;
  margin-top: 1px;
  padding-left: 12px;
  color: #000;
  overflow: hidden;
  word-break: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  padding-right: 22px;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  display: list-item;
}

.h-catlist:hover {
  background-color: #ffdc962b;
  color: #FF7700;
  border-left: 4px solid #FF7700;
  padding-left: 8px;
}

.h-catlist:active {
  background: #ffdc962b;
  color: #FF7700;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  border-left: 4px solid #FF7700;
  padding-left: 8px;
}

.h-catlist:hover .category-icon-right {
  color: #FF7700;
}

.category-icon-right {
  position: absolute;
  right: 8px;
  color: #c5c5c5;
  margin-top: 4px;
  font-size: 14px;
}

.activee {
  background-color: #ffdc962b;
  color: #FF7700;
  border-left: 4px solid #FF7700;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  padding-left: 8px;
}

.activee:hover {
  background-color: #ffdc962b;
  color: #FF7700;
}

.home-tags {
  margin-top: 20px;
}

.sidebar-brands {
  border-radius: 8px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-top: 6px;
  position: relative;
  top: 20px;

  height: 34px;
  margin-top: 26px;
  padding-left: 10px;
  width: 220px;
  background-color: #fff;

  cursor: pointer;
}
.sidebar-brands-icon {
  font-size: 20px;
  margin-right: 4px;
  color: #000;
}

.sidebar-brands-text {
  font-size: 14px;
  font-weight: bold;
  color: #000;
  position: relative;
  top: -2px;
}

.tags-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 12px;
  color: #000;
}
.ant-tag-checkable {
  font-size: 16px;
  margin: 2px;
}

.ant-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 16px;
  margin: 2px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: default;
}
.ant-tag:hover {
  opacity: 0.9;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: rgba(0, 0, 0, 0.65);
}

.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #fff;
}
.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  font-size: 13px;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #928e8e;
}
.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: #fff;
}
.ant-tag-checkable-checked {
  background-color: #000;
  color: #fff;
}
.ant-tag-checkable:active {
  background-color: #000;
  color: #fff;
}

/* .p-card {
  height: auto;
  width: 100%;
  padding-bottom: 6px;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #ededed;
  background-color: #fff;
}

.p-saved {
  background-color: #e4e5e9;
  position: absolute;
  right: 3px;
  top: 3px;
  border-radius: 0px 8px;
}
.p-saved-img {
  margin-top: 6px;
  margin-right: 8px;
  margin-left: 8px;
  width: 16px;
  height: 20px;
}

.p-img {
  height: 130px;
  width: 130px;
  margin-top: 10px;
  position: relative;
}

.premium {
  background-color: #000;
  color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 8px;
}

.card-bottom {
  margin: 5px 12px 12px 12px;
}

.price {
  font-size: 22px;
  font-weight: bold;
  color: #000;
  margin: 0;
}

.p-name {
  font-size: 12px;
  margin: 0;
}

.cart-btn {
  width: 100%;
  background-color: #fff;
  color: #FF7700;
  border: 1px solid #FF7700;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  font-weight: 600;
  height: 28px;
}

.cart-btn:hover {
  background-color: #FF7700;
  color: #fff;
  cursor: pointer;
}

.cart-count {
  width: 100%;
  border: 1px solid #FF7700;
  display: flex;
  justify-content: space-between;
  height: 28px;
  border-radius: 4px;
}

.minus {
  width: 45px;

  height: 28px;
  border-radius: 2px 0 0 2px;
  outline: none;
  background-color: #FF7700;
  border: 0;
  color: #fff;
  font-size: 22px;
  cursor: pointer;
}

.plus {
  width: 45px;

  height: 28px;
  border-radius: 0 2px 2px 0;
  outline: none;
  background-color: #FF7700;
  border: 0;
  color: #fff;
  font-size: 22px;
  cursor: pointer;
}

.plus:active {
  background-color: #FF7700;
}

.minus:active {
  background-color: #FF7700;
}

.counter {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
} */

.logo-bottom {
  position: relative;
  text-align: center;

  line-height: 16px;
  top: 10px;
  color: #101010;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.logo-bottom2 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #101010;
  text-align: center;
  margin-top: 10px;
}

.copyright {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #c0c0c0;
}

.footer-line {
  border-top: 1px solid #f0f0f0;
  width: 100%;
}

.social-icons {
  font-size: 22px;
  margin-right: 4px;
  float: left;
}

.social-container {
  float: left;
}
.footer-title {
  list-style-type: none;
  font-size: 18px;
  line-height: 42px;
  font-weight: 800;
}

.footer-title-num {
  list-style-type: none;
  font-size: 18px;
  line-height: 42px;
  font-weight: 800;
  color: #FF7700;
}

.ant-layout-footer {
  flex: 0 0 auto;

  position: relative;
  bottom: 0;
  overflow: hidden;
  overflow-x: hidden;
}

.ant-layout-footer {
  padding: 0px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background: #fff;
  overflow: hidden;
  overflow-x: hidden;
}

.h-footer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 20px;
  width: 100%;
}

.footer-menu {
  margin-top: 28px;
}

.footer-list {
  list-style-type: none;
  text-align: left;
  line-height: 34px;
  color: #010100;
  max-width: 226px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.footer-market {
  display: flex;
}

.search-select .ant-select-selector {
  outline: none;
  border-radius: 18px;
  background-color: #f2f5f7;
}

.search-select
  .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 18px;
  background-color: #f2f5f7;
  box-shadow: 0 0 0 0px rgba(43, 44, 43, 0.2);
  border: 0;
}

.search-select .ant-select:not(.ant-select-disabled) .ant-select-selector {
  border-right-width: 1px !important;
  border-radius: 18px;
  background-color: #f2f5f7;
  border: 0;
}

.search-select
  .ant-select:not(.ant-select-disabled):hover
  .ant-select-selector {
  border-right-width: 1px !important;
  border-radius: 18px;
  background-color: #f2f5f7;
  border: 0;
}

.main-sort {
  float: right;
  position: relative;
  color: #000;
  min-width: 140px;
  font-weight: bold;
  margin-top: 10px;
}

.main-sort .ant-select-selector {
  outline: none;
  border-radius: 16px;
  border: none;
  color: #000;
  min-width: 140px;
  font-weight: bold;
}

.main-sort
  .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  font-weight: bold;
  font-size: 16px;
  border: 1px solid #e4e5e9;
  color: #000;
  border-radius: 16px;
  box-shadow: none;
}

.main-sort .ant-select:not(.ant-select-disabled) .ant-select-selector {
  font-weight: bold;
  font-size: 16px;
  color: #000;
  height: 32px;
  border: 1px solid #e4e5e9;
  border-radius: 16px;
  min-width: 170px;
}

.main-sort .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  font-weight: bold;
  font-size: 16px;
  color: #000;
  height: 32px;
  border: 1px solid #e4e5e9;
  min-width: 170px;
  border-radius: 16px;
}

.main-sort-style {
  border-radius: 16px;
  padding: 0;
}

.main-sort-style
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: #009c48;
}
.main-sort-style
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #009c48;
  font-weight: 600;
  background-color: #ffdc962b;
}

.h-search {
  min-width: 180px;
  width: 100%;
  outline: none;
  text-align: left;
  top: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}

.r-search {
  width: 20%;
}

.filter-none {
  display: none;
}

.search-btn {
  z-index: 1000;
  font-size: 18px;
  color: #c0c0c0;
  line-height: 28px;
  position: relative;
  float: right;
  top: -24px;
  font-weight: bold;
  right: 10px;
}

.load-search {
  color: #000000;
  font-weight: bold;
  font-size: 16px;
  position: relative;
  float: right;
  top: -20px;
  right: 10px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: #1b1b1b;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  background-color: #f5f5f5;
}

.ant-select-item-option-disabled {
  color: rgba(0, 0, 0);
  cursor: default;
}

.tags-style {
  list-style: none;
}

.tag-li:active {
  color: #000;
}

.tag-li:focus {
  color: #fff;
}

.tag-style {
  text-align: left;
  display: inline-block;
  margin-right: 35px;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  height: 12px;
  zoom: 1;
  *display: inline;
  color: #010101;
  cursor: pointer;
  padding: 4px 7px 4px 7px;
}

.tag-style-badge {
  text-align: left;
  display: inline-block;
  margin-right: 58px;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  height: 12px;
  zoom: 1;
  *display: inline;
  color: #010101;
  cursor: pointer;
  padding: 4px 7px 4px 7px;
}

/* Active */
.tag-active {
  text-align: center;
  display: inline-block;
  margin-right: 35px;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  background: #2c77f3;
  border-radius: 10px;
  color: #fff;
  height: 20px;
  cursor: pointer;
}

.tag-active-badge {
  text-align: center;
  display: inline-block;
  margin-right: 58px;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  background: #2c77f3;
  border-radius: 10px;
  color: #fff;
  height: 20px;
  cursor: pointer;
}
.text-smart {
  color: #000;
}

.text-smart-badge {
  color: #000;
}

.tag-active .text-smart-badge {
  color: #fff;
}

.tag-active-badge .text-smart {
  color: #fff;
}

.tag-active .text-smart {
  color: #fff;
}
.new-badge {
  background-color: #2c77f3;
  padding: 1px 4px 1px 4px;
  border-radius: 7px;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 12px;
}

.cart-badge {
  height: 16px;
  min-width: 16px;
  text-align: center;
  border-radius: 7px;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 16px;
  background-color: #FF7700;
  border: 1px solid #fff;
  padding: 0 3px 0 3px;
}

.new-badge:active {
  color: #fff;
}

.new-badge:focus {
  color: #fff;
}
.cart-bottom-search {
  width: 260px;
}
.cart-name-search {
  font-size: 14px;
  margin-bottom: 0;
  margin-left: 2px;
  padding-top: 4px;
  color: #010101;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 14px; /* fallback */
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.cart-price-search {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  margin-left: 4px;
  color: #101010;
}

.search-product-img {
  height: 70px;
  width: 70px;
  object-fit: contain;
  margin-top: 16px;
  position: relative;
  margin-right: 6px;
  z-index: 1000;
}

.cart-items-search {
  border-bottom: 1px solid #e4e5e9;
}

.cart-btn-search {
  background-color: #fff;
  color: #FF7700;
  border: 1px solid #FF7700;
  line-height: 26px;
  border-radius: 14px;
  outline: none;
  font-weight: 600;
  height: 27px;
  font-size: 14px;
  width: 100px;
  position: relative;

  top: -10px;
}

.cart-btn-search:hover {
  background-color: #FF7700;
  color: #fff;
  cursor: pointer;
}

.cart-count-search {
  width: 100px;
  background-color: #FF7700;
  color: #fff;
  display: flex;
  height: 27px;
  border-radius: 14px;
  font-size: 18px;
  position: relative;
  top: -10px;
}

.minus-search {
  padding: 0;
  width: 30px;
  height: 27px;
  border-radius: 14px 0 0 14px;
  outline: none;
  background-color: #FF7700;
  border: 0;

  text-align: center;
  cursor: pointer;
  float: left;
}

.srch-plus {
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  position: relative;
  top: -1px;
}
.srch-minus {
  color: #fff;
  font-size: 32px;
  font-weight: 600;
}

.plus-search {
  padding: 0;
  height: 27px;
  border-radius: 0 14px 14px 0;
  outline: none;
  background-color: #FF7700;
  border: 0;
  width: 30px;
  cursor: pointer;
  float: right;
}

.plus-search:active {
  background-color: #FF7700;
}

.minus-search:active {
  background-color: #FF7700;
}

.counter-search {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  position: relative;
  height: 27px;
  width: 40px;
  top: 2px;
}
.cart-btn-search-text {
  position: relative;
  font-style: normal;
  font-weight: bold;
  top: -1px;
}

.search-option {
  width: 382px;
  border-radius: 181px;
}

.r-sebet-div {
  min-width: 92px;

  cursor: pointer;
}

.search-product-status {
  position: absolute;
  z-index: 1500;
}

.search-product-new {
  background-color: #FF7700;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  padding: 0px 4px 0px 4px;
}
.search-product-a {
  background-color: #fd0018;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  padding: 0px 4px 0px 4px;
  margin-top: 2px;
}

.search-dropdawn {
  border-radius: 8px;
  position: fixed;
}

.search-dropdawn ::-webkit-scrollbar {
  width: 8px;
}

.search-dropdawn ::-webkit-scrollbar:horizontal {
  height: 6px;
}

.search-dropdawn ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.search-dropdawn ::-webkit-scrollbar-thumb {
  border-radius: 10px;

  background-color: #888;
}
.search-dropdawn::-webkit-scrollbar-thumb:hover {
  background: rgb(255, 46, 46);
  cursor: pointer;
}

.breadcrumb {
  margin-bottom: 6px;
  background-color: #fff;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: 32px;
  padding: 5px 12px 5px 12px;

  box-shadow: 0px 1px 2px #ededed;
  border-radius: 16px;
}

.crumb-item {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #010101 !important;
  opacity: 1;
}

.crumb-item-active {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #010101 !important;
  opacity: 1;
}

.crumb-item:focus {
  font-weight: bold;
  color: #000;
}
.crumb-item:hover {
  font-weight: bold;
  color: #000000;
}

.brands-list {
  width: 100%;
  margin-top: 4px;
}

.brands-list-item {
  background-color: #fff;

  border-radius: 8px;
  box-shadow: 0px 1px 2px #ededed;
  z-index: 4;
  position: relative;
  top: 3px;
}
.brands-text {
  color: #000;
  position: relative;
  top: -2px;
  left: 2px;
}
.flex-container-footer {
  width: 100%;
}

.footer-logo {
  text-align: center;
  float: left;
  margin-right: 42px;
}

.footer-tabs {
  display: none;
}

.category-list-drawer {
  border: 0;

  display: inline;
}

.sub-drawer {
  width: 100%;
  margin: 0;
  border: 0;
  padding: 0;

  color: #010101;
}

.lang-mob {
  display: none;
}

.h-content {
  overflow: hidden;
  min-height: 100vh;
  margin-top: 100px;
}
.category-header {
  position: relative;
  left: 0;
  top: 14px;
  line-height: 32px;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 26px;
  color: #010101;
}

.more-btn {
  background-color: #FF7700;
  color: #fff;
  margin-top: 15px;
  outline: none;
  border: 1px solid #FF7700;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  padding: 1px 8px 1px 8px;
  font-weight: 500;
}

.home-lang {
  color: #010101;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 15px 8px 15px;
}

.home-lang-active {
  color: #009c48;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 15px 8px 15px;

  background-color: #ffdc962b;
}

.home-lang:hover {
  background-color: #ffdc962b;
  color: #009c48;
}

.home-lang-active:hover {
  background-color: #ffdc962b;
  color: #009c48;
}

.sidebar-menu {
  position: relative;
  width: 100%;
  display: inline-block;
}

.sidebar-menu:hover ul {
  visibility: visible;
  opacity: 1;
  padding: 4px 0 6px;
  position: absolute;
  top: 0;
}

.sidebar-menu ul {
  visibility: hidden;
  opacity: 0;
  z-index: 10000;
  top: 0px;
  position: absolute;
  left: 222px;
  min-width: 200px;
  background-color: #fff;
  border-radius: 8px;
  transition-property: opacity, visibility;
  transition: 0.3s step-end;
  box-shadow: 0px 1px 2px #ededed;
}

.subcat li {
  color: #010101;
  font-size: 16px;
  font-weight: normal;
  min-width: 200px;
  list-style-type: none;
  padding: 6px;
  padding-left: 10px;
  padding-right: 8px;
  margin-bottom: 1px;
}

.subcat li:hover {
  color: #FF7700;
  width: 100%;
  padding: 6px;
  padding-left: 6px;
  padding-right: 8px;
  background-color: #ffdc962b;
  border-left: 4px solid #FF7700;
  margin-bottom: 1px;
}

.subcat-active li {
  color: #FF7700;
  font-size: 16px;

  font-weight: normal;
  width: 100%;
  background-color: #ffdc962b;
  border-left: 4px solid #FF7700;
  padding-left: 6px;
  padding-right: 8px;
  margin-bottom: 1px;
}

.footer-txt-title {
  color: #010101;
  font-weight: 600;
}

.footer-txt-title:hover {
  color: #009c48;
}

.search-select .ant-select-auto-complete {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5715;
  list-style: none;
}
.search-select .ant-select-auto-complete .ant-select-clear {
  right: 13px;
}

.search-select .ant-select-single .ant-select-selector {
  display: flex;
}
.search-select
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-search {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  left: 11px;
}
.search-select
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-search-input {
  width: 100%;
}
.search-select
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.search-select
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  padding: 0;
  line-height: 30px;
  transition: all 0.3s;
}
@supports (-moz-appearance: meterbar) {
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 30px;
    font-weight: 700;
  }
}
.search-select
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  position: relative;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.search-select
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  pointer-events: none;
}
.search-select .ant-select-single .ant-select-selector::after,
.search-select
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item::after,
.search-select
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: "\a0";
}

.main-buttons {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-bottom: 16px;
}

.main-buttons li {
  float: left;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin-top: 10px;
  line-height: 30px;
  color: #010101;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background: #ffffff;
  margin-right: 6px;
  border: 1px solid #e4e5e9;
  height: 32px;
  padding: 0px 14px 0px 14px;
  border-radius: 16px;
}

.header-fixed {
  width: 100%;
  position: fixed;
  z-index: 1001;
}

.ant-message {
  z-index: 1510;
}

.load-more-layout {
  /* background-color: #fff; */
  width: 100%;
  text-align: center;
  margin-top: 50px;

  margin-bottom: 50px;
}

.more-btn {
  background-color: #FF7700;
  color: #fff;
  margin: 25px;
  outline: none;
  height: 40px;
  border: 1px solid #FF7700;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  border-radius: 20px;
  max-width: 200px;
  width: 100%;
}

.load-more {
  color: #FF7700;
  font-size: 28px;
  height: 40px;
  margin: 25px;
  line-height: 20px;
}

.load-icon-down {
  float: right;
  position: relative;
  top: 7px;
  right: 8px;
}

.product-card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 5;
}

.product-items {
  height: auto;
  max-height: 325px;
  padding-bottom: 6px;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #ededed;
  background-color: #fff;
  justify-content: space-between;
  width: 226px;
}

.sub-cat-cont {
  -webkit-filter: drop-shadow(4px 10px 16px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(4px 10px 16px rgba(0, 0, 0, 0.15));
}

.site-back-top-basic {
  color: rgba(64, 64, 64, 0.6);
}

.p-card {
  padding-bottom: 6px;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #ededed;
  background-color: #fff;
  min-width: 150px;
  height: 320px;
  min-height: 290px;
}

.p-saved {
  position: absolute;
  right: 10px;
  top: 10px;
}
.p-saved-img-active {
  color: #fd0018;
  font-size: 28px;
}

.p-saved-img {
  color: #f0f0f0;
  font-size: 28px;
}
.p-img {
  height: 180px;
  width: 180px;
  margin-top: 10px;
  position: relative;
  object-fit: contain;
}

.premium {
  background: #a533c1;
  border-radius: 20px;
  color: #fff;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  position: relative;
  top: 2px;
}

.card-bottom {
  margin: 5px 12px 12px 12px;
  background-color: #fff;
  height: 90px;
}

.price {
  font-size: 24px;
  font-weight: bold;
  color: #101010;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  position: relative;
  line-height: 26px;
  margin-top: -10px;
}

.p-name {
  height: 40px;
  margin-top: 25px;
  font-size: 14px;
  font-weight: 600;
  color: #101010;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.card-shop-icon {
  background-color: #FF7700;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  font-weight: 700;
  position: absolute;
  padding: 6px;
  right: 10px;
  top: 165px;
}

.card-shopping-icon {
  height: 22px;
  width: 22px;
}

.grid-5:hover .card-shop-icon {
  display: none;
}

.grid-5:hover .cart-btn {
  /* animation: mymove 0.8s; */
  display: block;
  cursor: pointer;
}

@-webkit-keyframes mymove {
  from {
    opacity: 0.5;
    display: none;
  }
  to {
    display: block;
    opacity: 1;
  }
}

@keyframes mymove {
  from {
    opacity: 0.5;
    display: none;
  }
  to {
    display: block;
    opacity: 1;
  }
}

.cart-btn {
  display: none;
  background-color: #FF7700;
  color: #ffffff;
  border: 0;
  border-radius: 14px;
  outline: none;
  font-size: 14px;

  height: 27px;
  text-align: center;
  position: absolute;
  right: 10px;
  width: 100px;
  top: 170px;
}

.cart-count {
  width: 100px;
  background-color: #FF7700;
  color: #fff;
  display: flex;
  height: 27px;
  border-radius: 14px;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 170px;
}

.minus {
  padding: 0;
  width: 30px;
  height: 27px;
  border-radius: 14px 0 0 14px;
  outline: none;
  background-color: #FF7700;
  border: 0;

  text-align: center;
  cursor: pointer;
  float: left;
}

.plus {
  padding: 0;
  height: 27px;
  border-radius: 0 14px 14px 0;
  outline: none;
  background-color: #FF7700;
  border: 0;
  width: 30px;
  cursor: pointer;
  float: right;
}
.icon-minus {
  position: relative;
  top: -1px;
}

.icon-plus {
  position: relative;
  top: -1px;
}

.counter {
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  position: relative;
  width: 40px;
  height: 27px;
  background-color: #FF7700;
}
.cart-btn-text {
  position: relative;
  top: -1px;
}

.top-img {
  height: 80px;
  width: 80px;
  margin-top: 8px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.top-name {
  font-size: 12px;
  margin-bottom: 0;
  width: 85%;
  padding-top: 12px;
}

.top-price {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  padding-bottom: 10px;
  color: #101010;
}

.top-icon {
  position: absolute;
  right: 18px;
  top: 26%;
  font-size: 26px;
  cursor: pointer;
  background: #101010;
  color: #fff;
  padding: 4px;
  border-radius: 4px;
}

.top-card {
  height: 94px;
  width: 100%;

  border-radius: 8px;
  box-shadow: 0px 1px 2px #ededed;
  background-color: #fff;
}

.product-status {
  position: absolute;
  z-index: 1000;
}

.product-new {
  background-color: #FF7700;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  padding: 0px 4px 0px 4px;
  margin-top: 10px;
  margin-left: 10px;
}
.product-a {
  background-color: #fd0018;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  padding: 0px 4px 0px 4px;
  margin-top: 4px;
  margin-left: 10px;
}

/* Shimmer skeletion */

.Shimmer {
  position: relative;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  min-width: 150px;
  height: auto;
}

.shimmerBG {
  -webkit-animation-duration: 2.2s;
          animation-duration: 2.2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: shimmer;
          animation-name: shimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1200px 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}

.media {
  height: 150px;
}

.p-32 {
  padding: 12px;
}

.title-line {
  height: 20px;
  width: 100%;
  margin-bottom: 8px;
  border-radius: 20px;
}

.content-line {
  height: 8px;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 8px;
}

.end {
  width: 40%;
}

@media (max-width: 992px) {
  .p-card {
    padding-bottom: 6px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px #ededed;
    background-color: #fff;
    min-width: 150px;
    height: 250px;
  }
}

/* ---- */

.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
  border-radius: 8px;
}
.slick-loading .slick-slide,
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  border-radius: 8px;
}
.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: 0;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  transform: translate3d(0, 0, 0);
}
.slick-track {
  top: 0;
  left: 0;
}
.slick-track:after,
.slick-track:before {
  display: table;
  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  border-radius: 8px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
  border-radius: 8px;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
} /*# sourceMappingURL=slick.min.css.map */

/* ikinji arrow dots */

.slick-dots,
.slick-next,
.slick-prev {
  position: absolute;
  display: block;
  padding: 0;
}
.slick-dots li button:before,
.slick-next:before,
.slick-prev:before {
  font-family: slick;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* .slick-loading .slick-list {
  background: url(ajax-loader.gif) center center no-repeat #fff;
} */

.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
}
.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  color: transparent;
  outline: 0;
  background: 0 0;
}
.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
  opacity: 1;
}
.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: 0.25;
}
.slick-next:before,
.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #fff;
}
.slick-prev {
  left: 10px;
  z-index: 1000;
}
[dir="rtl"] .slick-prev {
  right: 10px;
  left: auto;
}
.slick-prev:before {
  /* content: "<"; */
  background-image: url(/static/media/prev.3affb748.svg);
  background-size: 16px 16px;
  display: inline-block;
  width: 20px;
  height: 20px;
  content: "";
  fill: #010101;
  background-color: #f0f0f0;
  padding: 16px 16px 16px 16px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
}
.slick-next:before {
  background-image: url(/static/media/next.fc66fe86.svg);
  background-size: 16px 16px;
  display: inline-block;
  width: 20px;
  fill: #010101;
  height: 20px;
  content: "";
  background-color: #f0f0f0;
  padding: 16px 16px 16px 16px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
}
.slick-next {
  right: 22px;
}
[dir="rtl"] .slick-next {
  right: auto;
  left: 25px;
}
[dir="rtl"] .slick-next:before {
  content: "<";
}
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  /* bottom: -20px; */
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 1px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 12px;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}
.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0;
}

.slick-dots li button:hover {
  outline: 0;
  opacity: 0.8;
}
.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  /* top: -30px; */
  left: 0;
  width: 20px;
  height: 20px;
  content: "";
  text-align: center;
  background: url(/static/media/dot.3b9af0e0.svg);
  color: #000;
  background-repeat: no-repeat;
  opacity: 0.25;
}
.slick-dots li.slick-active button:before {
  opacity: 0.85;
  color: #000;
} /*# sourceMappingURL=slick-theme.min.css.map */

@media only screen and (min-width: 0px) and (max-width: 991px) {
  .slick-dots li button:before {
    position: absolute;
    /* top: -30px; */
    left: 0;
    background-image: none;
    width: 10px;
    height: 10px;
    content: "⚫";
    text-align: center;
    color: #000;
    opacity: 0.25;
  }

  .slick-prev:before {
    /* content: "<"; */
    background-image: url(/static/media/prev.3affb748.svg);
    background-size: 16px 16px;
    display: inline-block;
    width: 14px;
    height: 14px;
    content: "";
    background-color: #f0f0f0;
    padding: 12px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
    fill: #010101;
  }
  .slick-next:before {
    background-image: url(/static/media/next.fc66fe86.svg);
    background-size: 16px 16px;
    display: inline-block;
    width: 14px;
    fill: #010101;
    height: 14px;
    content: "";
    background-color: #f0f0f0;
    padding: 12px 12px 12px 12px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
  }
  .slick-next {
    right: 16px;
  }
}

.Slideshow {
  width: 100%;
  border-radius: 4px;
  height: auto;
  max-height: 100%;
}
/* Banner container  */
.banner-flex {
  width: 100%;
  height: auto;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* Uly surat container */
.banner-flex-item1 {
  width: 77%;

  border-radius: 4px;
  height: 100%;

  /* max-height: 300px; */

  /* background-color: #fff; */
}

/* Kici surat container */
.banner-flex-item2 {
  width: 23%;
  margin-left: 10px;
  height: auto;
  /* max-height: 300px; */
  border-radius: 4px;
}

/* Banner uly surat */
.banner {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.banner-item {
  height: 100%;
  max-height: 100%;
  width: auto;
}

/* .splide {
  border-radius: 32px;
} */

/* Banner kici surat */
.smallbanner {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.slide-page {
  background-color: #f0f0f0;
  width: 8px;
  height: 8px;
}

.slide-page.is-active {
  background-color: #010101;
  width: 8px;
  height: 8px;

  transform: none;
}

.slide-pagination {
  color: #010101;
}
/* 

.main-tags {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #010101;
  cursor: pointer;
  user-select: none;
  background: #ffffff;
  margin-right: 6px;
  border: 1px solid #e4e5e9;
  padding: 8px 10px 5px 10px;
  border-radius: 8px;
  height: 35px;
  float: left;
  position: relative;
  top: -18px;
}

.main-tags-active {
  font-style: normal;
  padding: 8px 10px 5px 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #010101;
  cursor: pointer;
  user-select: none;
  background: #ffffff;
  margin-right: 6px;
  border: 1px solid #010101;
  box-sizing: border-box;
  border-radius: 8px;
  height: 35px;
  float: left;
  position: relative;
  top: -18px;
} */

.product-grid {
  flex-basis: "20%";
  width: "100%";
  cursor: "pointer";
}

.line {
  border-top: 1px solid #101010;

  margin-bottom: 6px;
}

.newsreg {
  background-color: #fff;
  border-radius: 8px;
  height: 48px;
  width: 100%;
  padding-top: 4px;
}

.newstext {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 10px;
  color: #101010;
}

.newsinput {
  outline: none;
  width: 100%;
  border: 1px solid #101010;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  padding: 2px;
  margin-top: 4px;
}

.newsicon {
  font-size: 28px;
  margin-left: 10px;
  margin-right: 18px;
  color: #101010;
  position: relative;
  top: 5px;
}

.regbutton {
  width: 100% !important;
  margin-left: 20px;
  background: #101010;
  border-radius: 4px;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin-top: 4px;

  /* identical to box height */

  text-align: center;
}

.category-name {
  text-align: left;
  font-weight: bold;
  color: #010101;
  font-size: 24px;
  line-height: 20px;
  margin-bottom: 14px;
}

.view {
  background-color: #fff;

  box-shadow: 0px 1px 2px #ededed;
  border-radius: 16px;
  height: 32px;
}

.view-text {
  white-space: nowrap;
  padding: 6px 12px 3px 12px;
  margin-left: 5px;
  font-weight: 600;
  color: #101010;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.home-menu-cat {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  margin-bottom: 6px;
  width: 100%;
  justify-content: space-between;
}
/* 768px */
@media only screen and (min-width: 0px) and (max-width: 768px) {
  .Slideshow {
    width: 100%;
    border-radius: 8px;
    height: 12rem;
    min-width: 150px;
  }
  /* Banner container  */
  .banner-flex {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  /* Uly surat container */
  .banner-flex-item1 {
    width: 100%;
    border-radius: 8px;
    height: auto;

    /* background-color: #fff; */
  }
  /* Banner uly surat */
  .banner {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  /* Banner kici surat */
  .smallbanner {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  /* Kici surat container */
  .banner-flex-item2 {
    display: none;
  }
}

/* @media only screen and (min-width: 768) and (max-width: 991px) {
}

@media only screen and (min-width: 991px) and (max-width: 1200px) {
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
} */

.login-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #101010;
}

.login-name {
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  margin-top: 6px;
}

.ant-modal-content {
  border-radius: 8px;
}

.login-input {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #c0c0c0;
  border: 1px solid #c0c0c0;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 2px;
  margin-bottom: 5px;
}

.login-input-text {
  border: 0px;
  outline: none;
  width: 82%;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  border-radius: 4px;

  margin-left: 17.6%;
}

.login-input-text-pass {
  border: 0px;
  outline: none;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  border-radius: 4px;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.login-footer {
  position: relative;
  text-align: center;
  top: 10px;
}

.login-close {
  position: absolute;
  top: 0px;
  right: 0px;
  background: #f2f5f7;
  border-radius: 0px 8px;
  cursor: pointer;
}

.login-close-icon {
  font-size: 25px;
  color: #000000;
  transform: rotate(45deg);
  padding: 4px 4px 4px 4px;
  cursor: pointer;
}

.completed {
  text-align: center;
  margin: 0px auto;
  margin-bottom: 8px;
  border: 4px solid #78b13f;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  padding-top: 5px;
}

.completed-icon {
  text-align: center;
  margin: 0px auto;
  display: inline-block;
  transform: rotate(45deg);
  height: 65px;
  width: 25px;
  border-bottom: 5px solid #78b13f;
  border-right: 5px solid #78b13f;
}

.belgi {
  position: relative;
  top: 22.4px;
  left: -2px;
  margin-right: 15px;
  color: #000000;
  font-size: 18px;
  z-index: 1000;
  font-weight: 800;
}

.login-input-text-number {
  margin-left: 56px;
  position: relative;
  outline: none;
}

.belgi-name {
  line-height: 2px;
  position: relative;
  top: 5px;
}

.reset-pass {
  font-size: 14px;
  float: right;
  margin-bottom: 4px;
  cursor: pointer;
  color: #101010;
}

.reset-pass:hover {
  text-decoration: underline;
}



.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}
.classes_cOrderbutton__3gSe4 {
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    border-radius: 8px;
    border: 0;
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    color: #f9f9f9
}

.classes_cOrderbutton__3gSe4:disabled {
    cursor: default;
}


.classes_green_color__12Uco { 
    background-color: #FF7700;
}

.classes_green_color__12Uco:hover {
    background-color: #eb984f;
}

.classes_green_color__12Uco:active {
    background-color: #eb984f;
}
.classes_green_color__12Uco:disabled {
    background-color: #ee7912;
}


.classes_red_color__rVV4D { 
    background-color: rgb(240,10,10);
}

.classes_red_color__rVV4D:hover {
    background-color: rgba(240,10,10, 0.8);
}

.classes_red_color__rVV4D:active {
    background-color: rgb(220,9,9);
}
.classes_red_color__rVV4D:disabled {
    background-color: rgba(240,10,10, 0.5);
}



.classes_error__1ToxO {
    color: #F00A0A
}

.classes_Input__1qv08:focus{
    outline: none;
    background-color: white;
}

.classes_TextArea__3NvLL {
    outline: none;
    margin: 6px;
    border-radius: 8px;
    border: none;
    font-weight: 600;
    padding-left: 6px;
    width: 100%;
    max-height: 85%;
    min-height: 85%;
    font-size: 15px;
}
.cart-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  margin-top: 6px;
  margin-left: 0;
  color: #010101;
}

.cart-table-title {
  background: #e4e5e9;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0px 1px 2px #ededed;
}

.c-rows {
  height: 84px;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 0.5px solid #e4e5e9;
  margin-left: 6px;
  margin-right: 6px;

  min-width: 600px;
}

.cart-table-title-start {
  background: #e4e5e9;
  text-align: center;
  border-top-left-radius: 4px;
  text-align: center;
  box-shadow: 0px 1px 2px #ededed;
  font-size: 14px;
  font-weight: 600;
}

.cart-table-title-end {
  background: #e4e5e9;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  border-top-right-radius: 4px;

  text-align: center;
  box-shadow: 0px 1px 2px #ededed;
}

.cart-item {
  text-align: center;
  margin: auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #101010;
}

.c-img {
  margin-top: 6px;
  height: 72px;
  width: 72px;
}

.cart-pname {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #101010;
}

.c-count {
  width: 100px;
  background-color: #ff7700;
  color: #fff;
  display: flex;
  height: 26px;
  border-radius: 20px;
  font-size: 14px;
  position: relative;
}

.c-minus {
  padding: 0;
  width: 32px;
  height: 26px;
  border-radius: 20px 0 0 20px;
  outline: none;
  background-color: #ff7700;
  border: 0;
  color: #fff;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  float: left;
  padding-left: 4px;
}

.c-plus {
  padding: 0;
  height: 26px;
  border-radius: 0 20px 20px 0;
  outline: none;
  background-color: #ff7700;
  border: 0;
  color: #fff;
  font-size: 18px;
  width: 32px;
  cursor: pointer;
  padding-right: 4px;
  top: 0px;

  float: right;
}

.c-counter {
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  position: relative;
  width: 38px;
  height: 26px;
  background-color: #ff7700;
}

.c-del-icon {
  background: #f2f5f7;
  border-radius: 4px;
  float: right;
  padding-top: 6px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 2px;
  margin-left: 4px;
  margin-right: 10px;
}

.c-save-icon {
  background: #f2f5f7;
  border-radius: 4px;
  float: left;
  padding-top: 6px;
  padding-left: 5px;
  padding-right: 5px;
  color: #000;
  padding-bottom: 2px;
}

.c-input {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  outline: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
  padding: 6px;
  color: #000000;
}

.payment-method {
  width: 100%;
  display: block;
  border: 1px solid #404040;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: center;
  font-weight: bold;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 22px; /* fallback */
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  height: 25px;
  background-color: white;
}

.payment-method-active {
  width: 100%;
  display: block;
  border: 1px solid #ff7700;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  background-color: #ff7700;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: center;
  font-weight: bold;
  color: #fff;
  height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 22px; /* fallback */
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.c-address {
  border: 1px solid #404040;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  outline: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 6px;
  min-height: 28px;
  padding: 6px;
  color: #000000;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.c-address-active {
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid #ff7700;
  background-color: #f5f5f5;
  border-left: 2px solid #ff7700;
  border-right: 2px solid #ff7700;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  outline: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  margin-bottom: 6px;
  padding: 6px;
  padding-right: 18px;
  color: #000000;
}

.address-icon {
  color: #ff7700;
  position: absolute;
  top: 3px;
  right: 12px;
}

.c-notes {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  outline: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
  padding: 6px;
  color: #000000;
  max-height: 80px;

  resize: none;
}

.c-orderbutton {
  border-radius: 8px;
  width: 100%;
  border: 0;
  display: block;
  outline: none;
  margin-bottom: 10px;
  background-color: #ff7700;
  padding: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  text-align: center;
  color: #f9f9f9;
}

.c-total {
  height: 35px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 12px;
  text-align: center;
  color: #101010;
  background: #ffff;

  padding-top: 10px;
  margin-left: 6px;
  margin-right: 6px;
}

.ant-drawer-open-content {
  box-shadow: 0 3px 6px -4px rgb(255, 255, 255),
    0 6px 16px 0 rgba(255, 255, 255, 0.08),
    0 9px 28px 8px rgba(255, 255, 255, 0.05);
}

.ant-drawer-body::-webkit-scrollbar {
  width: 0px;
}

.drawer-cart-product-img {
  height: 75px;
  width: 75px;
  margin-top: 8px;
  margin-left: -10px;
}

.drawer-cart-shopicon {
  font-size: 18px;
  cursor: pointer;
  background: #f2f5f7;
  position: relative;
  color: rgb(0, 0, 0);
  padding: 4px;
  border-radius: 4px;
  left: 4px;
  top: 0px;
}

.drawer-cart-name {
  margin-bottom: 4px;
  margin-left: 4px;
  padding-top: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  height: 38px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 14px; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  color: #101010;
}

.drawer-cart-price {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 14px;
  margin-left: 4px;
  background-color: #fff;
  color: #101010;
  padding-top: 6px;
  margin-top: -4px;
  position: relative;
}

.drawer-cart-count {
  width: 75px;
  background-color: #ff7700;
  color: #fff;
  display: flex;
  height: 22px;
  border-radius: 20px;
  font-size: 14px;
  position: absolute;
  right: 2px;
  top: 30px;
}

.drawer-cart-minus {
  padding: 0;
  width: 25px;
  height: 22px;
  border-radius: 20px 0 0 20px;
  outline: none;
  background-color: #ff7700;
  border: 0;
  color: #fff;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  float: left;
}

.drawer-cart-plus {
  padding: 0;
  height: 22px;
  border-radius: 0 20px 20px 0;
  outline: none;
  background-color: #ff7700;
  border: 0;
  color: #fff;
  font-size: 16px;
  width: 25px;
  cursor: pointer;

  top: 0px;

  float: right;
}

.drawer-cart-counter {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  position: relative;
  width: 35px;

  background-color: #ff7700;
}

.drawer-cart-items {
  border-bottom: 1px solid #e4e5e9;
  color: #101010;
}

.drawer-top-icon {
  cursor: pointer;
  font-size: 14px;
  position: relative;
  top: 8px;

  background: #f2f5f7;

  color: #101010;
  padding: 4px 10px 4px 10px;
  border-radius: 4px;
}

.drawer-top {
  cursor: pointer;
  font-size: 14px;
  position: relative;
  top: 8px;
}

.address-button {
  border: 0;
  background-color: #fd1026;
  color: #fff;
  border-radius: 8;
}

.add-address {
  border: 1px solid #c4c4c4;
  border-radius: 6px;
}

.add-address-emty {
  border: 1px solid #fd1026;
  border-radius: 6px;
}

.cart-card {
  width: 100%;
  padding-bottom: 6px;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #ededed;
  background-color: #fff;
}

.cart-header {
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 12px;
  padding-top: 16px;
  border-bottom: 1px solid #e4e5e9;
  padding-bottom: 14px;
  color: #101010;
}

.total-items-cart {
  float: left;
  font-size: 16px;
  color: #101010;
}

.cart-order-total {
  align-content: center;
  color: #101010;
  font-size: 16px;
}

.cart-clear {
  float: right;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  position: relative;
  top: -18px;
  background: #f2f5f7;
  line-height: 8px;
  color: #101010;
  padding: 8px 8px 8px 8px;
  border-radius: 8px;
}

.cart-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-layout {
  width: 450px;
  margin-left: 10px;
}

.profile-input {
  width: 100%;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;
  margin-top: 10px;
  padding-left: 4px;
}

.cart-header1 {
  text-align: left;
  position: "relative";
  left: 0;
  top: 6px;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  color: #101010;
}

.p-add-icon {
  text-align: center;
  cursor: pointer;
}

.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 8px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}

.address-area {
  width: 100%;
  outline: none;
  border-radius: 4px;
  resize: none;
  border: 0px solid #f5f5f5;
  font-size: 16px;
  border-top: 1px solid #cfcbcb;
  border-left: 1px solid #e9e6e6;
  border-right: 1px solid #e9e6e6;
}

.address-area-empty {
  width: 100%;
  outline: none;
  border-radius: 4px;
  resize: none;
  border: 0px solid #cc3838;
  font-size: 16px;
  border-top: 1px solid #e91b1b;
  border-left: 1px solid #fa0606;
  border-bottom: 1px solid #fa0606;
  /* background-color: #f1f1f1; */
  border-right: 1px solid #fa0606;
}

.address-btn {
  margin: 4px;
  width: 30%;
  padding: 1px;
  border: 1px solid #c0c0c0;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #101010;
  cursor: pointer;
  background-color: #fff;
}

.str-length {
  position: absolute;
  right: 10px;
  bottom: 15px;
  font-size: 12px;
  font-weight: 600;
}

@media only screen and (min-width: 0px) and (max-width: 991px) {
  .cart-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cart-clear {
    float: right;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    position: relative;
    top: 2px;
    background: #f2f5f7;

    color: rgb(0, 0, 0);
    padding: 5px 5px 8px 5px;
    border-radius: 8px;
  }

  .cart-item {
    text-align: center;
    margin: auto;
  }
  .cart-pname {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
  }
  .cart-icon {
    display: flex;
    flex-direction: column;
  }

  .total-items-cart {
    float: left;
    font-size: 14px;
  }
  .cart-order-total {
    text-align: center;

    font-size: 14px;
  }

  .cart-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .card-layout {
    width: 100%;
    margin-top: 10px;
    border-radius: 8;
    margin-left: 0;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1200px) {
  .cart-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cart-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .card-layout {
    width: 100%;
    margin-top: 10px;
    border-radius: 8;
    margin-left: 0;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen and (min-width: 0px) and (max-width: 991px) {
  .r-none {
    display: none;
  }
  .r-show {
    display: block;
  }

  .logo-small {
    height: 50px;
    width: 50px;
    margin-top: -5px;
    margin-left: 6px;
  }
  .h-container {
    width: 94%;
  }
  .h-content {
    overflow: hidden;
    min-height: 100vh;
    margin-top: 70px;
  }
  .h-search {
    width: 100%;
  }

  .r-search {
    width: 100%;
  }

  .r-sebet {
    font-size: 26px;
  }

  .r-sebet-div {
    padding-right: 8px;
  }
  .r-sebet-div {
    min-width: 10px;
    text-align: right;
  }

  .search-option {
    width: 100%;
  }
  .search-dropdawn {
    width: 75%;
  }

  .top-header {
    height: 60px;
  }

  .single-main {
    border: none;
  }

  .r-left {
    padding-left: 25px;
  }

  .h-footer {
    margin-bottom: 36px;
  }

  .flex-container-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  .footer-menu {
    width: 100%;
  }
  .footer-logo {
    text-align: center;
  }

  .footer-list {
    width: 100%;
    position: relative;
    left: -18px;
  }

  /* Mobile bottom tabs  */
  .footer-tabs {
    overflow: hidden;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    margin: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px -1px 2px #ededed;
    /* border-radius: 20px 20px 0px 0px; */
  }

  .bottom-tabs-icon {
    color: #010101;
    text-align: center;
    padding: 6px 8px;
    text-decoration: none;
    font-size: 22px;
    width: 100%;
  }

  .tab-active {
    color: #FF7700;
  }

  .tab-active:hover {
    color: #FF7700;
  }

  .tab-active:focus {
    color: #FF7700;
  }

  .card-layout {
    width: 100%;
    margin-top: 10px;
    border-radius: 8;
    margin-left: 0;
  }

  .p-img {
    height: 150px;
    width: 150px;
  }

  .c-rows {
    height: 84px;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 0.5px solid #e4e5e9;
    margin-left: 6px;
    margin-right: 6px;
    width: 100%;
    min-width: 100%;
  }

  .c-count {
    width: 60px;
    background-color: #FF7700;
    color: #fff;
    display: flex;
    height: 18px;
    border-radius: 20px;
    font-size: 12px;
    position: relative;
  }

  .c-img {
    margin-top: 6px;
    height: 50px;
    width: 50px;
  }

  .c-minus {
    padding: 0;
    width: 22px;
    height: 20px;
    border-radius: 10px 0 0 10px;
    outline: none;
    background-color: #FF7700;
    border: 0;
    color: #fff;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    float: left;
    padding-left: 4px;
    padding-top: 3px;
  }

  .c-plus {
    padding: 0;
    height: 20px;
    border-radius: 0 10px 10px 0;
    outline: none;
    background-color: #FF7700;
    border: 0;
    color: #fff;
    font-size: 12px;
    width: 22px;
    cursor: pointer;
    padding-right: 4px;
    padding-top: 3px;
    top: 0px;

    float: right;
  }

  .c-counter {
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    position: relative;
    width: 24px;
    height: 20px;
    background-color: #FF7700;
  }

  .c-save-icon {
    background: #f2f5f7;
    border-radius: 4px;
    margin-top: 2px;
    font-size: 10px;
    margin-right: 9px;
    padding: 4px 4px 2px 4px;
    width: 25px;
    color: #000;
  }

  .c-del-icon {
    background: #f2f5f7;
    border-radius: 4px;
    margin-top: 2px;
    font-size: 10px;
    margin-right: 9px;
    padding: 4px 4px 2px 4px;
    width: 25px;
    color: #000;
  }

  .order-hitory {
    font-size: 8px;
  }

  .expanded-table {
    font-size: 12px;
  }

  .order-history-img {
    height: 45px;
    width: 45px;
  }

  .order-header {
    font-size: 12px;
    width: auto;
  }

  .h-sidebar {
    background-color: #fff;
    margin-top: -15px;
    margin-left: -10px;
  }

  .lang-mob {
    display: block;
  }

  .sider {
    background-color: #f2f5f7;
  }

  .filter-none {
    display: block;
  }

  .p-profile {
    padding: 0;
  }

  .profile-layout-2 {
    padding-left: 0;
  }

  .single-main {
    padding-left: 0px;
  }
  /* 
  .p-card {
    height: auto;
    max-height: 286px;
    padding-bottom: 6px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px #ededed;
    background-color: #fff;
  }

  .card-bottom {
    background-color: #fff;
    height: 80px;
    margin-top: -18px;
  }

  .top-name {
    font-size: 12px;
    margin-bottom: 0;
    width: 85%;
    padding-top: 12px;
  }

  .top-price {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    padding-bottom: 10px;
    color: #101010;
  }

  .p-name {
    height: 40px;
    margin-top: 25px;
    font-size: 14px;
    font-weight: 600;
    color: #101010;
    line-height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;  
    -webkit-box-orient: vertical;
  } */

  /* breadcrumb */

  /* .card-shop-icon {
    background-color: #FF7700;
    color: #ffffff;
    border-radius: 50%;
    padding: 6px;
    font-weight: 700;
    font-size: 26px;
    position: absolute;
    right: 10px;
    top: 135px;
  } */

  .card-shop-icon {
    background-color: #FF7700;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    font-weight: 700;
    position: absolute;
    padding: 6px;
    right: 10px;
    top: 138px;
  }

  .card-shopping-icon {
    height: 22px;
    width: 22px;
  }

  .cart-btn {
    display: none;
    background-color: #FF7700;
    color: #ffffff;
    border: 0;
    border-radius: 20px;
    outline: none;
    font-size: 14px;

    height: 26px;
    text-align: center;
    position: absolute;
    right: 10px;
    width: 100px;
    top: 140px;
  }

  .cart-count {
    width: 100px;
    background-color: #FF7700;
    color: #fff;
    display: flex;
    height: 26px;
    border-radius: 20px;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 140px;
  }

  .breadcrumb {
    margin-bottom: 6px;
    background-color: transparent;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0px;
    box-shadow: none;
    border: none;

    line-height: 18px;
  }

  .crumb-item {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #010101 !important;
    opacity: 1;
  }

  .crumb-item-active {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #010101 !important;
  }

  .crumb-item:focus {
    font-weight: bold;
    color: #000;
  }
  .crumb-item:hover {
    font-weight: bold;
    color: #000000;
  }
  .for-none {
    display: none;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1200px) {
  .menu-text-none {
    display: none;
  }
  .sm-cont {
    left: 6%;
    position: relative;
  }

  .home-menu-cat {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    margin-bottom: 6px;
  }
  .text-smart {
    font-size: 10px;
  }
  /*.p-img {
    height: 125px;
    width: 125px;
  } */

  /* .p-card {
    height: auto;
    max-height: 265px;
    padding-bottom: 6px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px #ededed;
    background-color: #fff;
  } */
}

@media only screen and (min-width: 1200px) and (max-width: 1340px) {
  .text-smart {
    font-size: 12px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  /* .p-card {
    height: auto;
    max-height: 350px;
    padding-bottom: 6px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px #ededed;
    background-color: #fff;
  } */
}

@media only screen and (min-width: 1601px) {
  .r-container-single {
    margin-left: 15px;
  }

  /* .p-card {
    height: auto;
    max-height: 350px;
    padding-bottom: 6px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px #ededed;
    background-color: #fff;
  } */
}

@media (min-width: 992px) {
  .ant-col-lg-4 {
    display: block;
    flex: 0 0 19.93333333%;
    max-width: 19.93333333%;
  }

  .menu-items-lang {
    margin-top: 10px;
    margin-right: 10px;
    text-align: center;
    font-size: 14px;
    width: 52px;
  }

  .menu-items-contact {
    margin-top: 10px;
    margin-right: 10px;
    text-align: center;
    font-size: 14px;
    width: 112px;
  }
  .menu-items-saved {
    margin-top: 10px;
    margin-right: 10px;
    text-align: center;
    font-size: 14px;
    width: 110px;
  }
}

.menu-items-profile {
  margin-top: 10px;
  margin-right: 10px;
  text-align: center;
  font-size: 14px;
  width: 85px;
}

@media only screen and (min-width: 768) and (max-width: 991px) {
  .ant-col-lg-4 {
    display: block;
    flex: 0 0 22%;
    max-width: 22%;
  }
  .for-none {
    display: none;
  }
}

/* Tablets, iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 992px) and (max-width: 1050px) {
  .ant-col-lg-4 {
    display: block;
    flex: 0 0 32.8%;
    max-width: 32.8%;
  }
  .for-none {
    display: none;
  }
}

/* Tablets, iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 1051px) and (max-width: 1180px) {
  .ant-col-lg-4 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .card-container {
    justify-content: center;
  }
  .for-none {
    display: none;
  }
}

/* Tablets, iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 1181px) and (max-width: 1350px) {
  .ant-col-lg-4 {
    display: block;
    flex: 0 0 22%;
    max-width: 22%;
  }
  .for-none {
    display: none;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */
}

/* Tablets, iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
}

/* Tablets, iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */
}

/* iPhone 4 ----------- */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  /* Styles */
}

/* nunito-200 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 200;
  font-display: block;
  src: url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-200.6b764a99.eot); /* IE9 Compat Modes */
  src: local("Nunito ExtraLight"), local("Nunito-ExtraLight"),
    url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-200.6b764a99.eot?#iefix)
      format("embedded-opentype"),
     dd
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-200.27484dd4.woff2)
      format("woff2"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-200.b0c81e32.woff)
      format("woff"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-200.de0699a9.ttf)
      format("truetype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-200.dc47d2f0.svg#Nunito)
      format("svg"); /* Legacy iOS */
}
/* nunito-200italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 200;
  src: url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-200italic.7b078f6b.eot); /* IE9 Compat Modes */
  src: local("Nunito ExtraLight Italic"), local("Nunito-ExtraLightItalic"),
    url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-200italic.7b078f6b.eot?#iefix)
      format("embedded-opentype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-200italic.581d5e2b.woff2)
      format("woff2"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-200italic.5284a793.woff)
      format("woff"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-200italic.359c1485.ttf)
      format("truetype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-200italic.7f5f237b.svg#Nunito)
      format("svg"); /* Legacy iOS */
}
/* nunito-300 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-300.0218760f.eot); /* IE9 Compat Modes */
  src: local("Nunito Light"), local("Nunito-Light"),
    url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-300.0218760f.eot?#iefix)
      format("embedded-opentype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-300.a8a2d0db.woff2)
      format("woff2"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-300.10de1159.woff)
      format("woff"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-300.a41d3d59.ttf)
      format("truetype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-300.97cf2be3.svg#Nunito)
      format("svg"); /* Legacy iOS */
}
/* nunito-300italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 300;
  src: url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-300italic.51359280.eot); /* IE9 Compat Modes */
  src: local("Nunito Light Italic"), local("Nunito-LightItalic"),
    url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-300italic.51359280.eot?#iefix)
      format("embedded-opentype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-300italic.3a03afcc.woff2)
      format("woff2"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-300italic.5c0df82c.woff)
      format("woff"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-300italic.1cff81ed.ttf)
      format("truetype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-300italic.1a78ce14.svg#Nunito)
      format("svg"); /* Legacy iOS */
}
/* nunito-regular - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-regular.4793b1da.eot); /* IE9 Compat Modes */
  src: local("Nunito Regular"), local("Nunito-Regular"),
    url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-regular.4793b1da.eot?#iefix)
      format("embedded-opentype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-regular.b4942e1c.woff2)
      format("woff2"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-regular.67abefd7.woff)
      format("woff"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-regular.8173d856.ttf)
      format("truetype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-regular.12f55de8.svg#Nunito)
      format("svg"); /* Legacy iOS */
}
/* nunito-italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 400;
  src: url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-italic.82ba3ac4.eot); /* IE9 Compat Modes */
  src: local("Nunito Italic"), local("Nunito-Italic"),
    url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-italic.82ba3ac4.eot?#iefix)
      format("embedded-opentype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-italic.c8caa955.woff2)
      format("woff2"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-italic.32825baf.woff)
      format("woff"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-italic.31b2b68a.ttf)
      format("truetype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-italic.5b342eeb.svg#Nunito)
      format("svg"); /* Legacy iOS */
}
/* nunito-600 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  src: url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-600.fe9b85c1.eot); /* IE9 Compat Modes */
  src: local("Nunito SemiBold"), local("Nunito-SemiBold"),
    url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-600.fe9b85c1.eot?#iefix)
      format("embedded-opentype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-600.48bec895.woff2)
      format("woff2"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-600.2874f432.woff)
      format("woff"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-600.9870af4d.ttf)
      format("truetype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-600.0e500c75.svg#Nunito)
      format("svg"); /* Legacy iOS */
}
/* nunito-600italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 600;
  src: url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-600italic.d7890a63.eot); /* IE9 Compat Modes */
  src: local("Nunito SemiBold Italic"), local("Nunito-SemiBoldItalic"),
    url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-600italic.d7890a63.eot?#iefix)
      format("embedded-opentype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-600italic.442ff7e2.woff2)
      format("woff2"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-600italic.2322600d.woff)
      format("woff"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-600italic.e3f641a4.ttf)
      format("truetype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-600italic.be86af96.svg#Nunito)
      format("svg"); /* Legacy iOS */
}
/* nunito-700 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-700.1112b1cd.eot); /* IE9 Compat Modes */
  src: local("Nunito Bold"), local("Nunito-Bold"),
    url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-700.1112b1cd.eot?#iefix)
      format("embedded-opentype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-700.73117947.woff2)
      format("woff2"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-700.25eb0a75.woff)
      format("woff"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-700.1410fa8e.ttf)
      format("truetype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-700.d7b5fda7.svg#Nunito)
      format("svg"); /* Legacy iOS */
}
/* nunito-700italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 700;
  src: url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-700italic.842a25c4.eot); /* IE9 Compat Modes */
  src: local("Nunito Bold Italic"), local("Nunito-BoldItalic"),
    url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-700italic.842a25c4.eot?#iefix)
      format("embedded-opentype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-700italic.ab63f50a.woff2)
      format("woff2"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-700italic.698cbea2.woff)
      format("woff"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-700italic.ed4dc48b.ttf)
      format("truetype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-700italic.517af7c5.svg#Nunito)
      format("svg"); /* Legacy iOS */
}
/* nunito-800 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  src: url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-800.b17d2ca4.eot); /* IE9 Compat Modes */
  src: local("Nunito ExtraBold"), local("Nunito-ExtraBold"),
    url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-800.b17d2ca4.eot?#iefix)
      format("embedded-opentype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-800.c1dbb109.woff2)
      format("woff2"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-800.91028784.woff)
      format("woff"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-800.99411541.ttf)
      format("truetype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-800.7acfcfa9.svg#Nunito)
      format("svg"); /* Legacy iOS */
}
/* nunito-900 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  src: url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-900.17102f84.eot); /* IE9 Compat Modes */
  src: local("Nunito Black"), local("Nunito-Black"),
    url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-900.17102f84.eot?#iefix)
      format("embedded-opentype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-900.e174711c.woff2)
      format("woff2"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-900.21ce32bf.woff)
      format("woff"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-900.bf6dedbe.ttf)
      format("truetype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-900.32bdc22f.svg#Nunito)
      format("svg"); /* Legacy iOS */
}
/* nunito-800italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 800;
  src: url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-800italic.c9be6bbd.eot); /* IE9 Compat Modes */
  src: local("Nunito ExtraBold Italic"), local("Nunito-ExtraBoldItalic"),
    url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-800italic.c9be6bbd.eot?#iefix)
      format("embedded-opentype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-800italic.a54224ad.woff2)
      format("woff2"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-800italic.18f58c92.woff)
      format("woff"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-800italic.466704c2.ttf)
      format("truetype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-800italic.b6ef6f9f.svg#Nunito)
      format("svg"); /* Legacy iOS */
}
/* nunito-900italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 900;

  src: url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-900italic.427ac2be.eot); /* IE9 Compat Modes */
  src: local("Nunito Black Italic"), local("Nunito-BlackItalic"),
    url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-900italic.427ac2be.eot?#iefix)
      format("embedded-opentype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-900italic.7c5840cb.woff2)
      format("woff2"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-900italic.1005d6d9.woff)
      format("woff"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-900italic.200a7c83.ttf)
      format("truetype"),
    
      url(/static/media/nunito-v14-latin-ext_latin_cyrillic-ext_cyrillic-900italic.d1854814.svg#Nunito)
      format("svg"); /* Legacy iOS */
}

body {
  margin: 0;
  color: #101010;
  background-color: #f2f5f7;
}

body {
  font-family: "Nunito";
  background-color: #f2f5f7;
}
html {
  height: 100%;
  --antd-wave-shadow-color: #ffffff;
}

.ant-layout {
  background-color: #f2f5f7;
}
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar:horizontal {
  height: 6px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-left: 1px solid #aaaaaa;
}

/* 





*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #888;
}
::-webkit-scrollbar-thumb:hover {
  /* background: rgb(255, 46, 46); */
  background-color: rgb(107, 106, 106);
  cursor: pointer;
}

.sider {
  background-color: #f2f5f7;
}

