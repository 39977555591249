.cart-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  margin-top: 6px;
  margin-left: 0;
  color: #010101;
}

.cart-table-title {
  background: #e4e5e9;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0px 1px 2px #ededed;
}

.c-rows {
  height: 84px;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 0.5px solid #e4e5e9;
  margin-left: 6px;
  margin-right: 6px;

  min-width: 600px;
}

.cart-table-title-start {
  background: #e4e5e9;
  text-align: center;
  border-top-left-radius: 4px;
  text-align: center;
  box-shadow: 0px 1px 2px #ededed;
  font-size: 14px;
  font-weight: 600;
}

.cart-table-title-end {
  background: #e4e5e9;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  border-top-right-radius: 4px;

  text-align: center;
  box-shadow: 0px 1px 2px #ededed;
}

.cart-item {
  text-align: center;
  margin: auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #101010;
}

.c-img {
  margin-top: 6px;
  height: 72px;
  width: 72px;
}

.cart-pname {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #101010;
}

.c-count {
  width: 100px;
  background-color: #ff7700;
  color: #fff;
  display: flex;
  height: 26px;
  border-radius: 20px;
  font-size: 14px;
  position: relative;
}

.c-minus {
  padding: 0;
  width: 32px;
  height: 26px;
  border-radius: 20px 0 0 20px;
  outline: none;
  background-color: #ff7700;
  border: 0;
  color: #fff;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  float: left;
  padding-left: 4px;
}

.c-plus {
  padding: 0;
  height: 26px;
  border-radius: 0 20px 20px 0;
  outline: none;
  background-color: #ff7700;
  border: 0;
  color: #fff;
  font-size: 18px;
  width: 32px;
  cursor: pointer;
  padding-right: 4px;
  top: 0px;

  float: right;
}

.c-counter {
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  position: relative;
  width: 38px;
  height: 26px;
  background-color: #ff7700;
}

.c-del-icon {
  background: #f2f5f7;
  border-radius: 4px;
  float: right;
  padding-top: 6px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 2px;
  margin-left: 4px;
  margin-right: 10px;
}

.c-save-icon {
  background: #f2f5f7;
  border-radius: 4px;
  float: left;
  padding-top: 6px;
  padding-left: 5px;
  padding-right: 5px;
  color: #000;
  padding-bottom: 2px;
}

.c-input {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  outline: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
  padding: 6px;
  color: #000000;
}

.payment-method {
  width: 100%;
  display: block;
  border: 1px solid #404040;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  font-weight: bold;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 22px; /* fallback */
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  height: 25px;
  background-color: white;
}

.payment-method-active {
  width: 100%;
  display: block;
  border: 1px solid #ff7700;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  background-color: #ff7700;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  font-weight: bold;
  color: #fff;
  height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 22px; /* fallback */
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.c-address {
  border: 1px solid #404040;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  outline: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 6px;
  min-height: 28px;
  padding: 6px;
  color: #000000;
  cursor: pointer;
  user-select: none;
}

.c-address-active {
  cursor: pointer;
  user-select: none;
  border: 1px solid #ff7700;
  background-color: #f5f5f5;
  border-left: 2px solid #ff7700;
  border-right: 2px solid #ff7700;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  outline: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  margin-bottom: 6px;
  padding: 6px;
  padding-right: 18px;
  color: #000000;
}

.address-icon {
  color: #ff7700;
  position: absolute;
  top: 3px;
  right: 12px;
}

.c-notes {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  outline: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
  padding: 6px;
  color: #000000;
  max-height: 80px;

  resize: none;
}

.c-orderbutton {
  border-radius: 8px;
  width: 100%;
  border: 0;
  display: block;
  outline: none;
  margin-bottom: 10px;
  background-color: #ff7700;
  padding: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  text-align: center;
  color: #f9f9f9;
}

.c-total {
  height: 35px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 12px;
  text-align: center;
  color: #101010;
  background: #ffff;

  padding-top: 10px;
  margin-left: 6px;
  margin-right: 6px;
}

.ant-drawer-open-content {
  -webkit-box-shadow: 0 3px 6px -4px rgb(255, 255, 255),
    0 6px 16px 0 rgba(255, 255, 255, 0.08),
    0 9px 28px 8px rgba(255, 255, 255, 0.05);
  box-shadow: 0 3px 6px -4px rgb(255, 255, 255),
    0 6px 16px 0 rgba(255, 255, 255, 0.08),
    0 9px 28px 8px rgba(255, 255, 255, 0.05);
}

.ant-drawer-body::-webkit-scrollbar {
  width: 0px;
}

.drawer-cart-product-img {
  height: 75px;
  width: 75px;
  margin-top: 8px;
  margin-left: -10px;
}

.drawer-cart-shopicon {
  font-size: 18px;
  cursor: pointer;
  background: #f2f5f7;
  position: relative;
  color: rgb(0, 0, 0);
  padding: 4px;
  border-radius: 4px;
  left: 4px;
  top: 0px;
}

.drawer-cart-name {
  margin-bottom: 4px;
  margin-left: 4px;
  padding-top: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  height: 38px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 14px; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  color: #101010;
}

.drawer-cart-price {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 14px;
  margin-left: 4px;
  background-color: #fff;
  color: #101010;
  padding-top: 6px;
  margin-top: -4px;
  position: relative;
}

.drawer-cart-count {
  width: 75px;
  background-color: #ff7700;
  color: #fff;
  display: flex;
  height: 22px;
  border-radius: 20px;
  font-size: 14px;
  position: absolute;
  right: 2px;
  top: 30px;
}

.drawer-cart-minus {
  padding: 0;
  width: 25px;
  height: 22px;
  border-radius: 20px 0 0 20px;
  outline: none;
  background-color: #ff7700;
  border: 0;
  color: #fff;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  float: left;
}

.drawer-cart-plus {
  padding: 0;
  height: 22px;
  border-radius: 0 20px 20px 0;
  outline: none;
  background-color: #ff7700;
  border: 0;
  color: #fff;
  font-size: 16px;
  width: 25px;
  cursor: pointer;

  top: 0px;

  float: right;
}

.drawer-cart-counter {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  position: relative;
  width: 35px;

  background-color: #ff7700;
}

.drawer-cart-items {
  border-bottom: 1px solid #e4e5e9;
  color: #101010;
}

.drawer-top-icon {
  cursor: pointer;
  font-size: 14px;
  position: relative;
  top: 8px;

  background: #f2f5f7;

  color: #101010;
  padding: 4px 10px 4px 10px;
  border-radius: 4px;
}

.drawer-top {
  cursor: pointer;
  font-size: 14px;
  position: relative;
  top: 8px;
}

.address-button {
  border: 0;
  background-color: #fd1026;
  color: #fff;
  border-radius: 8;
}

.add-address {
  border: 1px solid #c4c4c4;
  border-radius: 6px;
}

.add-address-emty {
  border: 1px solid #fd1026;
  border-radius: 6px;
}

.cart-card {
  width: 100%;
  padding-bottom: 6px;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #ededed;
  background-color: #fff;
}

.cart-header {
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 12px;
  padding-top: 16px;
  border-bottom: 1px solid #e4e5e9;
  padding-bottom: 14px;
  color: #101010;
}

.total-items-cart {
  float: left;
  font-size: 16px;
  color: #101010;
}

.cart-order-total {
  align-content: center;
  color: #101010;
  font-size: 16px;
}

.cart-clear {
  float: right;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  position: relative;
  top: -18px;
  background: #f2f5f7;
  line-height: 8px;
  color: #101010;
  padding: 8px 8px 8px 8px;
  border-radius: 8px;
}

.cart-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-layout {
  width: 450px;
  margin-left: 10px;
}

.profile-input {
  width: 100%;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;
  margin-top: 10px;
  padding-left: 4px;
}

.cart-header1 {
  text-align: left;
  position: "relative";
  left: 0;
  top: 6px;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  color: #101010;
}

.p-add-icon {
  text-align: center;
  cursor: pointer;
}

.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 8px;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}

.address-area {
  width: 100%;
  outline: none;
  border-radius: 4px;
  resize: none;
  border: 0px solid #f5f5f5;
  font-size: 16px;
  border-top: 1px solid #cfcbcb;
  border-left: 1px solid #e9e6e6;
  border-right: 1px solid #e9e6e6;
}

.address-area-empty {
  width: 100%;
  outline: none;
  border-radius: 4px;
  resize: none;
  border: 0px solid #cc3838;
  font-size: 16px;
  border-top: 1px solid #e91b1b;
  border-left: 1px solid #fa0606;
  border-bottom: 1px solid #fa0606;
  /* background-color: #f1f1f1; */
  border-right: 1px solid #fa0606;
}

.address-btn {
  margin: 4px;
  width: 30%;
  padding: 1px;
  border: 1px solid #c0c0c0;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #101010;
  cursor: pointer;
  background-color: #fff;
}

.str-length {
  position: absolute;
  right: 10px;
  bottom: 15px;
  font-size: 12px;
  font-weight: 600;
}

@media only screen and (min-width: 0px) and (max-width: 991px) {
  .cart-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cart-clear {
    float: right;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    position: relative;
    top: 2px;
    background: #f2f5f7;

    color: rgb(0, 0, 0);
    padding: 5px 5px 8px 5px;
    border-radius: 8px;
  }

  .cart-item {
    text-align: center;
    margin: auto;
  }
  .cart-pname {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
  }
  .cart-icon {
    display: flex;
    flex-direction: column;
  }

  .total-items-cart {
    float: left;
    font-size: 14px;
  }
  .cart-order-total {
    text-align: center;

    font-size: 14px;
  }

  .cart-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .card-layout {
    width: 100%;
    margin-top: 10px;
    border-radius: 8;
    margin-left: 0;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1200px) {
  .cart-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cart-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .card-layout {
    width: 100%;
    margin-top: 10px;
    border-radius: 8;
    margin-left: 0;
  }
}
