.cOrderbutton {
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    border-radius: 8px;
    border: 0;
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    color: #f9f9f9
}

.cOrderbutton:disabled {
    cursor: default;
}


.green_color { 
    background-color: #FF7700;
}

.green_color:hover {
    background-color: #eb984f;
}

.green_color:active {
    background-color: #eb984f;
}
.green_color:disabled {
    background-color: #ee7912;
}


.red_color { 
    background-color: rgb(240,10,10);
}

.red_color:hover {
    background-color: rgba(240,10,10, 0.8);
}

.red_color:active {
    background-color: rgb(220,9,9);
}
.red_color:disabled {
    background-color: rgba(240,10,10, 0.5);
}



.error {
    color: #F00A0A
}

.Input:focus{
    outline: none;
    background-color: white;
}

.TextArea {
    outline: none;
    margin: 6px;
    border-radius: 8px;
    border: none;
    font-weight: 600;
    padding-left: 6px;
    width: 100%;
    max-height: 85%;
    min-height: 85%;
    font-size: 15px;
}