/* Smartphones (portrait) ----------- */
@media only screen and (min-width: 0px) and (max-width: 991px) {
  .r-none {
    display: none;
  }
  .r-show {
    display: block;
  }

  .logo-small {
    height: 50px;
    width: 50px;
    margin-top: -5px;
    margin-left: 6px;
  }
  .h-container {
    width: 94%;
  }
  .h-content {
    overflow: hidden;
    min-height: 100vh;
    margin-top: 70px;
  }
  .h-search {
    width: 100%;
  }

  .r-search {
    width: 100%;
  }

  .r-sebet {
    font-size: 26px;
  }

  .r-sebet-div {
    padding-right: 8px;
  }
  .r-sebet-div {
    min-width: 10px;
    text-align: right;
  }

  .search-option {
    width: 100%;
  }
  .search-dropdawn {
    width: 75%;
  }

  .top-header {
    height: 60px;
  }

  .single-main {
    border: none;
  }

  .r-left {
    padding-left: 25px;
  }

  .h-footer {
    margin-bottom: 36px;
  }

  .flex-container-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  .footer-menu {
    width: 100%;
  }
  .footer-logo {
    text-align: center;
  }

  .footer-list {
    width: 100%;
    position: relative;
    left: -18px;
  }

  /* Mobile bottom tabs  */
  .footer-tabs {
    overflow: hidden;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    margin: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px -1px 2px #ededed;
    /* border-radius: 20px 20px 0px 0px; */
  }

  .bottom-tabs-icon {
    color: #010101;
    text-align: center;
    padding: 6px 8px;
    text-decoration: none;
    font-size: 22px;
    width: 100%;
  }

  .tab-active {
    color: #FF7700;
  }

  .tab-active:hover {
    color: #FF7700;
  }

  .tab-active:focus {
    color: #FF7700;
  }

  .card-layout {
    width: 100%;
    margin-top: 10px;
    border-radius: 8;
    margin-left: 0;
  }

  .p-img {
    height: 150px;
    width: 150px;
  }

  .c-rows {
    height: 84px;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 0.5px solid #e4e5e9;
    margin-left: 6px;
    margin-right: 6px;
    width: 100%;
    min-width: 100%;
  }

  .c-count {
    width: 60px;
    background-color: #FF7700;
    color: #fff;
    display: flex;
    height: 18px;
    border-radius: 20px;
    font-size: 12px;
    position: relative;
  }

  .c-img {
    margin-top: 6px;
    height: 50px;
    width: 50px;
  }

  .c-minus {
    padding: 0;
    width: 22px;
    height: 20px;
    border-radius: 10px 0 0 10px;
    outline: none;
    background-color: #FF7700;
    border: 0;
    color: #fff;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    float: left;
    padding-left: 4px;
    padding-top: 3px;
  }

  .c-plus {
    padding: 0;
    height: 20px;
    border-radius: 0 10px 10px 0;
    outline: none;
    background-color: #FF7700;
    border: 0;
    color: #fff;
    font-size: 12px;
    width: 22px;
    cursor: pointer;
    padding-right: 4px;
    padding-top: 3px;
    top: 0px;

    float: right;
  }

  .c-counter {
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    position: relative;
    width: 24px;
    height: 20px;
    background-color: #FF7700;
  }

  .c-save-icon {
    background: #f2f5f7;
    border-radius: 4px;
    margin-top: 2px;
    font-size: 10px;
    margin-right: 9px;
    padding: 4px 4px 2px 4px;
    width: 25px;
    color: #000;
  }

  .c-del-icon {
    background: #f2f5f7;
    border-radius: 4px;
    margin-top: 2px;
    font-size: 10px;
    margin-right: 9px;
    padding: 4px 4px 2px 4px;
    width: 25px;
    color: #000;
  }

  .order-hitory {
    font-size: 8px;
  }

  .expanded-table {
    font-size: 12px;
  }

  .order-history-img {
    height: 45px;
    width: 45px;
  }

  .order-header {
    font-size: 12px;
    width: auto;
  }

  .h-sidebar {
    background-color: #fff;
    margin-top: -15px;
    margin-left: -10px;
  }

  .lang-mob {
    display: block;
  }

  .sider {
    background-color: #f2f5f7;
  }

  .filter-none {
    display: block;
  }

  .p-profile {
    padding: 0;
  }

  .profile-layout-2 {
    padding-left: 0;
  }

  .single-main {
    padding-left: 0px;
  }
  /* 
  .p-card {
    height: auto;
    max-height: 286px;
    padding-bottom: 6px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px #ededed;
    background-color: #fff;
  }

  .card-bottom {
    background-color: #fff;
    height: 80px;
    margin-top: -18px;
  }

  .top-name {
    font-size: 12px;
    margin-bottom: 0;
    width: 85%;
    padding-top: 12px;
  }

  .top-price {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    padding-bottom: 10px;
    color: #101010;
  }

  .p-name {
    height: 40px;
    margin-top: 25px;
    font-size: 14px;
    font-weight: 600;
    color: #101010;
    line-height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;  
    -webkit-box-orient: vertical;
  } */

  /* breadcrumb */

  /* .card-shop-icon {
    background-color: #FF7700;
    color: #ffffff;
    border-radius: 50%;
    padding: 6px;
    font-weight: 700;
    font-size: 26px;
    position: absolute;
    right: 10px;
    top: 135px;
  } */

  .card-shop-icon {
    background-color: #FF7700;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    font-weight: 700;
    position: absolute;
    padding: 6px;
    right: 10px;
    top: 138px;
  }

  .card-shopping-icon {
    height: 22px;
    width: 22px;
  }

  .cart-btn {
    display: none;
    background-color: #FF7700;
    color: #ffffff;
    border: 0;
    border-radius: 20px;
    outline: none;
    font-size: 14px;

    height: 26px;
    text-align: center;
    position: absolute;
    right: 10px;
    width: 100px;
    top: 140px;
  }

  .cart-count {
    width: 100px;
    background-color: #FF7700;
    color: #fff;
    display: flex;
    height: 26px;
    border-radius: 20px;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 140px;
  }

  .breadcrumb {
    margin-bottom: 6px;
    background-color: transparent;
    width: fit-content;
    padding: 0px;
    box-shadow: none;
    border: none;

    line-height: 18px;
  }

  .crumb-item {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #010101 !important;
    opacity: 1;
  }

  .crumb-item-active {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #010101 !important;
  }

  .crumb-item:focus {
    font-weight: bold;
    color: #000;
  }
  .crumb-item:hover {
    font-weight: bold;
    color: #000000;
  }
  .for-none {
    display: none;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1200px) {
  .menu-text-none {
    display: none;
  }
  .sm-cont {
    left: 6%;
    position: relative;
  }

  .home-menu-cat {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    margin-bottom: 6px;
  }
  .text-smart {
    font-size: 10px;
  }
  /*.p-img {
    height: 125px;
    width: 125px;
  } */

  /* .p-card {
    height: auto;
    max-height: 265px;
    padding-bottom: 6px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px #ededed;
    background-color: #fff;
  } */
}

@media only screen and (min-width: 1200px) and (max-width: 1340px) {
  .text-smart {
    font-size: 12px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  /* .p-card {
    height: auto;
    max-height: 350px;
    padding-bottom: 6px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px #ededed;
    background-color: #fff;
  } */
}

@media only screen and (min-width: 1601px) {
  .r-container-single {
    margin-left: 15px;
  }

  /* .p-card {
    height: auto;
    max-height: 350px;
    padding-bottom: 6px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px #ededed;
    background-color: #fff;
  } */
}

@media (min-width: 992px) {
  .ant-col-lg-4 {
    display: block;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 19.93333333%;
    flex: 0 0 19.93333333%;
    max-width: 19.93333333%;
  }

  .menu-items-lang {
    margin-top: 10px;
    margin-right: 10px;
    text-align: center;
    font-size: 14px;
    width: 52px;
  }

  .menu-items-contact {
    margin-top: 10px;
    margin-right: 10px;
    text-align: center;
    font-size: 14px;
    width: 112px;
  }
  .menu-items-saved {
    margin-top: 10px;
    margin-right: 10px;
    text-align: center;
    font-size: 14px;
    width: 110px;
  }
}

.menu-items-profile {
  margin-top: 10px;
  margin-right: 10px;
  text-align: center;
  font-size: 14px;
  width: 85px;
}

@media only screen and (min-width: 768) and (max-width: 991px) {
  .ant-col-lg-4 {
    display: block;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 22%;
    flex: 0 0 22%;
    max-width: 22%;
  }
  .for-none {
    display: none;
  }
}

/* Tablets, iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 992px) and (max-width: 1050px) {
  .ant-col-lg-4 {
    display: block;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 32.8%;
    flex: 0 0 32.8%;
    max-width: 32.8%;
  }
  .for-none {
    display: none;
  }
}

/* Tablets, iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 1051px) and (max-width: 1180px) {
  .ant-col-lg-4 {
    display: block;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .card-container {
    justify-content: center;
  }
  .for-none {
    display: none;
  }
}

/* Tablets, iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 1181px) and (max-width: 1350px) {
  .ant-col-lg-4 {
    display: block;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 22%;
    flex: 0 0 22%;
    max-width: 22%;
  }
  .for-none {
    display: none;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */
}

/* Tablets, iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
}

/* Tablets, iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */
}

/* iPhone 4 ----------- */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  /* Styles */
}
